import React, { useState,useEffect } from "react";
import "../offers.css";
import { useParams } from "react-router";
import axiosIns from "../components/axiosIns";
import { storage } from "../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";
import { Alert } from "../components/Toastify";

const AddService = () => {
  const [offerData, setOfferData] = useState({
    name: "",
    picture: "",
  });

  const { name, picture } = offerData;
  const handleChange = (event) => {
    setOfferData({ ...offerData, [event.target.name]: event.target.value });
  };

  const [offerPic, setProfile] = useState(null);
  const [profileCheck, setPicCheck] = useState(null);
  const [showLoader, setShowLoader] = useState(false);


  const handleSubmit = async (event) => {
    event.preventDefault();
    setShowLoader(true);
    // setShowSuccessMsg(true);
    if (offerPic == null) {
       Alert("error","Please Choose File");
       setShowLoader(false);
       return false;
    } else {
      if (profileCheck == null) {
        const imageRef = ref(storage, `service/${offerPic.name + v4()}`);
        await uploadBytes(imageRef, offerPic).then((res) => {
          return getDownloadURL(ref(storage, res.metadata.fullPath)).then(
            (url) => {
              offerData.picture = url;
              setOfferData(offerData);
              setPicCheck(offerPic);
            }
          );
        });
      } else {
        if (profileCheck.name != offerPic.name) {
          const imageRef = ref(storage, `service/${offerPic.name + v4()}`);
          await uploadBytes(imageRef, offerPic).then((res) => {
            return getDownloadURL(ref(storage, res.metadata.fullPath)).then(
              (url) => {
                offerData.picture = url;
                setOfferData(offerData);
                setPicCheck(offerPic);
              }
            );
          });
        }
      }
    }
    if (offerData.picture != "") {
      await axiosIns
        .post("/add_service", offerData)
        .then((res) => {
          if (res.data.status === true) {
            Alert("succ",res.data.msg);
            window.location.replace("/");
          } else Alert("warn",res.data.msg);
        })
        .catch((err) => {
          if (err.response.data.msg == "token") {
            localStorage.clear("isLogin");
            window.location.reload("/");
          } else
          Alert("error","Please Try Again...");
          console.log(err.response);
        });
    }  
  };

  const params = useParams();

  useEffect(()=>{
    const checkTokenExpiration = setInterval(() => {
          const expirationTime = localStorage.getItem("tokenExpirationTime");
          if (expirationTime && Date.now() > parseInt(expirationTime)) {
            localStorage.clear("isLogin");
            window.location.reload("/");
          }
        }, 1000);
    
          clearInterval(checkTokenExpiration);
          if (params.id) {
            get_service_details();
          }
    }, []);

    const get_service_details = async()=>{
      await axiosIns
        .post("/get_service", { id: params.id })
        .then((res) => {
          offerData.id = res.data.data._id;
          offerData.name = res.data.data.name;
          offerData.picture = res.data.data.picture;
          setOfferData({...offerData}); 
          setProfile(res.data.data.picture);
          setPicCheck(res.data.data.picture);
          console.log(offerData)
        }).catch((err) => {
          if (err.response.data.msg == "token") {
            localStorage.clear("isLogin");
            window.location.reload("/");
          } else
            Alert ("error", "Please Try Again...");
            setShowLoader(false);
        });
    }


  return (
    <div className="container">
      <div className="row">
        <h1>Add Service</h1>
        <div className="col-md-6">
          <div className="image-boxs">
            <label>
              Picture <sapn>(175*175)</sapn>
            </label>
            <input
              type="file"
              className="form-control"
              onChange={(e) => {
                setProfile(e.target.files[0]);
              }}
            />
              <img src={picture ? picture : "/images/noimg.jpg"} width={175} height={175} />
          </div>
        </div>
        <div className="col-md-6">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="title">
                Name<span className="span">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder=""
                value={name}
                name="name"
                onChange={handleChange}
                required
              />
            </div>
            <div className="buttons-container">
              <button type="submit" className="upload-button"disabled={showLoader}>
              {showLoader ? (
                  <div className="spinner-border spinner-border-sm" role="status"
                  aria-hidden="true"></div>
                ) : ( 
                  "Upload"
                )
              }
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddService;
