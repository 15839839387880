import React, { useState, useEffect } from "react";
import "../register.css";
import { useParams } from "react-router";
import axiosIns from "../../components/axiosIns";
import { storage } from "../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";
import { Alert } from "../../components/Toastify";
const AddDoctor = () => {
  const params = useParams();
  const [doctorData, setData] = useState({
    name: "",
    email: "",
    age: "",
    mobile: "",
    gender: "",
    experience: "",
    qualification: "",
    mode_of_consultant: "",
    available_time: "",
    clinic_name: "",
    clinic_address: "",
    picture: "",
    password: "",
    aboutus: "",
  });
  const [doctorProfile, setProfile] = useState(null);
  const [profileCheck, setPicCheck] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const {
    name,
    email,
    age,
    mobile,
    gender,
    experience,
    qualification,
    aboutus,
    mode_of_consultant,
    available_time,
    clinic_address,
    clinic_name,
    picture,
    password,
  } = doctorData;
  const handleSelectChange = (event) => {
    const values = Array.from(
      event.target.selectedOptions,
      (option) => option.value
    );
    console.log(values);
    setData({ ...doctorData, ["mode_of_consultant"]: values });
  };

  const handleChange = (e) => {
    setData({ ...doctorData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowLoader(true);
    if (doctorProfile == null) {
      Alert("warn", "Please Choose File");
      setShowLoader(false);
    } else {
      if (profileCheck == null) {
        const imageRef = ref(storage, `doctor/${doctorProfile.name + v4()}`);
        await uploadBytes(imageRef, doctorProfile).then((res) => {
          return getDownloadURL(ref(storage, res.metadata.fullPath)).then(
            (url) => {
              doctorData.picture = url;
              setData(doctorData);
              setPicCheck(doctorProfile);
            }
          );
        });
      } else {
        if (profileCheck.name != doctorProfile.name) {
          const imageRef = ref(storage, `doctor/${doctorProfile.name + v4()}`);
          await uploadBytes(imageRef, doctorProfile).then((res) => {
            getDownloadURL(ref(storage, res.metadata.fullPath)).then((url) => {
              doctorData.picture = url;
              setData(doctorData);
              setPicCheck(doctorProfile);
            });
          });
        }
      }
    }
    if (doctorData.picture) {
      if (params.id) var api_url = "/doctor_update";
      else var api_url = "/doctor_register";
       setTimeout(async () => {
        await axiosIns
          .post(api_url, doctorData)
          .then((res) => {
            if (res.data.status === true) {
              Alert("succ", res.data.msg);
              setShowLoader(false);
              window.location.replace("/doctor");
            } else Alert("warn", res.data.msg);
            setShowLoader(false);
          })
          .catch((err) => {
            Alert("error", "Please Try Again...");
            setShowLoader(false);
          });
      }, 3000);
    } else {
      if (doctorProfile.name)
        Alert("error", "Please Wait Profile Uploading...");
      else Alert("error", "Please Choose File...");
      setShowLoader(false);
    }    
  };

  const [servicelist, setserviceData] = useState([]);
  const getData = async () => {
    await axiosIns
      .post("/service_list")
      .then((res) => {
        setserviceData(res.data.data);
        console.log(servicelist);
      })
      .catch((err) => {
        if (err.response.data.msg == "token") {
          localStorage.clear("isLogin");
          window.location.reload("/");
        }
        console.log(err.response);
      });
  };

  useEffect(() => {
    const checkTokenExpiration = setInterval(() => {
      const expirationTime = localStorage.getItem("tokenExpirationTime");
      if (expirationTime && Date.now() > parseInt(expirationTime)) {
        localStorage.clear("isLogin");
        window.location.reload("/");
      }
    }, 1000);

    clearInterval(checkTokenExpiration);
    getData();
    if (params.id) {
      get_doctor_details();
    }
  }, []);

  var service = servicelist.map((row) => {
    return (
      <option key={row._id} value={row._id}>
        {row.name}
      </option>
    );
  });

  const get_doctor_details = async () => {
    await axiosIns
      .post("/get_doctor", { id: params.id })
      .then((res) => {
        doctorData.id = res.data.data._id;
        doctorData.picture = res.data.data.picture;
        doctorData.name = res.data.data.name;
        doctorData.age = res.data.data.age;
        doctorData.gender = res.data.data.gender;
        doctorData.experience = res.data.data.experience;
        doctorData.mobile = res.data.data.mobile;
        doctorData.email = res.data.data.email;
        doctorData.qualification = res.data.data.qualification;
        doctorData.available_time = res.data.data.available_time;
        doctorData.clinic_address = res.data.data.clinic_address;
        doctorData.clinic_name = res.data.data.clinic_name;
        doctorData.mode_of_consultant = res.data.data.mode_of_consultant;
        doctorData.aboutus = res.data.data.aboutus;

        setData({ ...doctorData });
        setProfile(res.data.data.picture);
        setPicCheck(res.data.data.picture);
        console.log(doctorProfile);
        console.log(profileCheck);
      })
      .catch((err) => {
        if (err.response.data.msg == "token") {
          localStorage.clear("isLogin");
          window.location.reload("/");
        } else Alert("error", "Please Try Again...");
        setShowLoader(false);
      });
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <h1 style={{ alignItems: "center" }}>Add Doctor</h1>
              <div className="col-md-4">
                <label>
                  Doctor Name <span className="span">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={handleChange}
                  placeholder="Doctor Name"
                  name="name"
                />
              </div>
              <div className="col-md-4">
                <label>
                  Profile <sapn>(155*200)</sapn> <span className="span">*</span>
                </label>
                <input
                  type="file"
                  className="form-control"
                  onChange={(e) => {
                    setProfile(e.target.files[0]);
                  }}
                  placeholder="Age"
                  name="picture"
                />
                <img
                  src={picture ? picture : "/images/noimg.jpg"}
                  width={150}
                  height={150}
                />
              </div>
              <div className="col-md-4">
                <label>
                  Age <span className="span">*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  value={age}
                  onChange={handleChange}
                  placeholder="Age"
                  name="age"
                />
              </div>
              <div className="col-md-4">
                <label>
                  Gender <span className="span">*</span>
                </label>
                <select
                  className="form-control"
                  value={gender}
                  onChange={handleChange}
                  placeholder="Gender"
                  name="gender"
                >
                  <option value="">--Select--</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Others">Others</option>
                </select>
              </div>
              <div className="col-md-4">
                <label>Experience</label>
                <input
                  type="text"
                  className="form-control"
                  value={experience}
                  onChange={handleChange}
                  placeholder="Experience"
                  name="experience"
                />
              </div>
              <div className="col-md-4">
                <label>
                  Mobile <span className="span">*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  value={mobile}
                  onChange={handleChange}
                  placeholder="Mobile"
                  name="mobile"
                />
              </div>
              <div className="col-md-4">
                <label>
                  Email Id <span className="span">*</span>
                </label>
                <input
                  type="email"
                  className="form-control"
                  value={email}
                  onChange={handleChange}
                  placeholder="Email Id"
                  name="email"
                />
              </div>
              <div className="col-md-4">
                <label>
                  Qualification <span className="span">*</span>
                </label>
                <input
                  type="email"
                  className="form-control"
                  value={qualification}
                  onChange={handleChange}
                  placeholder="Qualification"
                  name="qualification"
                />
              </div>
              <div className="col-md-4">
                <label>
                  Available Time <span className="span">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={available_time}
                  onChange={handleChange}
                  placeholder="Time"
                  name="available_time"
                />
              </div>
              <div className="col-md-4">
                <label>
                  Clinic Name <span className="span">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={clinic_name}
                  onChange={handleChange}
                  placeholder="Clinic Name"
                  name="clinic_name"
                />
              </div>
              <div className="col-md-4">
                <label>
                  Clinic Address <span className="span">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={clinic_address}
                  onChange={handleChange}
                  placeholder="Company Address"
                  name="clinic_address"
                />
              </div>
              {params.id ? null : (
                <div className="col-md-4">
                  <label>
                    Password <span className="span">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={password}
                    onChange={handleChange}
                    placeholder="Enter Your Password"
                    name="password"
                  />
                </div>
              )}
              <div className="col-md-4">
                <label>
                  Mode Of Consultant <span className="span">*</span>
                </label>
                <select
                  className="form-control"
                  multiple={true}
                  value={mode_of_consultant}
                  onChange={handleSelectChange}
                  name="mode_of_consultant"
                >
                  {service}
                </select>
              </div>
              <div className="col-md-8">
                <label>About Us</label>
                <textarea
                  value={aboutus}
                  rows={4}
                  className="form-control"
                  onChange={handleChange}
                  placeholder=""
                  name="aboutus"
                ></textarea>
              </div>
              <div
                className="col-md-12"
                align="center"
                style={{ padding: "10px" }}
              >
                <button
                  onClick={handleSubmit}
                  type="button"
                  class="btn btn-success"
                  disabled={showLoader}
                >
                  {showLoader ? (
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></div>
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddDoctor;
