import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import axiosIns from "../../components/axiosIns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import { Alert } from "../../components/Toastify";
const styles = [
  `
  .bt_view{ height: 20px !important; margin-left: 20px; width: 20px !important; font-size: 14px; height: 30px; }
  .rdt_TableRow{ padding: 1%; }
`,
];

const addDropDown = (param) => {
  console.log(param);
  return (
    <>
      <div>
        <Link>
          <FontAwesomeIcon
            icon={faSignInAlt}
            onClick={handleDeleteAll.bind(this, param._id)}
            style={{ color: "#50C878" }}
            className="bt_view"
          />
        </Link>
      </div>
    </>
  );
};

const handleDeleteAll = async (id) => {
  const choice = window.confirm("Are you sure you want to delete this Tariff?");
  if (choice) {
    await axiosIns
      .post("/tariff_delete", { id: id, status: 1 })
      .then((res) => {
        if (res.data.status) {
          Alert("succ", res.data.msg);
          window.location.reload(true);
        } else Alert("err", res.data.msg);
      })
      .catch((err) => {
        Alert("err", "Please Try Again");
      });
  }
};

const columns = [
  {
    name: "Name",
    selector: "name",
    sortable: true,
  },
  {
    name: "Gender",
    selector: "gender",
    sortable: "true",
  },
  {
    name: "Experience",
    selector: "experience",
    sortable: "true",
  },
  {
    name: "Phone",
    selector: "mobile",
    sortable: "true",
  },
  {
    name: "Clinic Address",
    selector: "clinic_address",
    sortable: "true",
  },
  {
    name: "Date",
    selector: "dob",
  },
  {
    name: "Actions",
    // selector :'actions',
    selector: (param) => addDropDown(param),
  },
];

function DeletedTariff() {
  const [data, setData] = useState([]);
  // const userToken = localStorage.getItem('token');
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    // axios.defaults.headers.common['Authorization'] = userToken
    await axiosIns
      .post("/deactive_tariff_list")
      .then((res) => {
        setData(res.data.data);
        setFilteredData(res.data.data);
        setLoading(false);
        console.log(data);
      })
      .catch((err) => {
        if (err.response.data.msg == "token") {
          localStorage.clear("isLogin");
          window.location.reload("/");
        }
        console.log(err.response);
      });
  };

  useEffect(() => {
    const checkTokenExpiration = setInterval(() => {
      const expirationTime = localStorage.getItem("tokenExpirationTime");
      if (expirationTime && Date.now() > parseInt(expirationTime)) {
        localStorage.clear("isLogin");
        window.location.reload("/");
      }
    }, 1000);

    clearInterval(checkTokenExpiration);
    getData();
  }, []);

  const [filteredData, setFilteredData] = useState(data);

  const filterData = (value) => {
    const lowerCaseValue = value.toLowerCase().trim();
    const filteredData = data.filter((item) =>
      item.name.toLowerCase().includes(lowerCaseValue)
    );
    setFilteredData(filteredData);
  };

  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    filterData(event.target.value);
  };

  return (
    <>
      <style>{styles}</style>
      <div className="App" style={{ width: "100%" }}>
        <div align="right">
          <input
            style={{
              width: "24%",
              borderRadius: "50px",
              marginRight: "300px",
              padding: "20px",
            }}
            type="text"
            placeholder="Search Tariff by Name"
            value={searchValue}
            onChange={handleSearch}
          />
          <Link to="/delete_doctor" style={{ marginRight: "4px" }}>
            <Button variant="primary">Doctor</Button>
          </Link>
          <Link to="/delete_company" style={{ marginRight: "4px" }}>
            <Button variant="primary">Company</Button>
          </Link>
          <Link to="/delete_employee" style={{ marginRight: "4px" }}>
            <Button variant="primary">Employee</Button>
          </Link>
          <Link to="/delete_offers" style={{ marginRight: "4px" }}>
            <Button variant="primary">Offers</Button>
          </Link>
          <Link to="/delete_tariff" style={{ marginRight: "4px" }}>
            <Button variant="primary">Tariff</Button>
          </Link>
          <Link to="/delete_ads" style={{ marginRight: "4px" }}>
            <Button variant="primary">Ads</Button>
          </Link>
          <Link to="/delete_mode" style={{ marginRight: "4px" }}>
            <Button variant="primary">Mode</Button>
          </Link>
        </div>
        {/* <div align="right"><Button variant="primary">Add</Button></div> */}
        {loading ? (
          <table className="loader">
            <tbody>
              <tr>
                <td></td>
              </tr>
            </tbody>
          </table>
        ) : (
          <DataTable
            title="Deleted Tariffs"
            columns={columns}
            data={filteredData}
            pagination
            highlightOnHover
          />
        )}
      </div>
    </>
  );
}
export default DeletedTariff;
