import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
// import axios from 'axios';
import axiosIns from "../components/axiosIns";

const getProfile = (param) => {
  return (
    <>
      <img
        height={105}
        width={85}
        style={{ borderRadius: "10%" }}
        src={param.picture ? param.picture : "./images/noimg.jpg"}
      />
    </>
  );
};

const columns = [
  {
    name: "Name",
    selector: "name",
    sortable: true,
  },
  {
    name: "Profile",
    selector: (param) => getProfile(param),
    sortable: false,
  },
  {
    name: "Gender",
    selector: "gender",
    sortable: "true",
  },
  {
    name: "Experience",
    selector: "experience",
    sortable: "true",
  },
  {
    name: "Phone",
    selector: "mobile",
    sortable: "true",
  },
  {
    name: "Clinic Address",
    selector: "clinic_address",
    sortable: "true",
  },
  // {
  //   name :'Status',
  //   selector :'status',
  // },
];

function DataSection() {
  const [data, setData] = useState([]);
  // const userToken = localStorage.getItem('token');
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    // axios.defaults.headers.common['Authorization'] = userToken
    await axiosIns
      .post("/deactive_doctor_list")
      .then((res) => {
        setData(res.data.data);
        setLoading(false);
        setFilteredData(res.data.data);
        console.log(data);
      })
      .catch((err) => {
        if (err.response.data.msg == "token") {
          localStorage.clear("isLogin");
          window.location.reload("/");
        }
        console.log(err.response);
      });
  };

  useEffect(() => {
    const checkTokenExpiration = setInterval(() => {
      const expirationTime = localStorage.getItem("tokenExpirationTime");
      if (expirationTime && Date.now() > parseInt(expirationTime)) {
        localStorage.clear("isLogin");
        window.location.reload("/");
      }
    }, 1000);

    clearInterval(checkTokenExpiration);
    getData();
  }, []);

  const [filteredData, setFilteredData] = useState(data);

  const filterData = (value) => {
    const lowerCaseValue = value.toLowerCase().trim();
    const filteredData = data.filter((item) =>
      item.name.toLowerCase().includes(lowerCaseValue)
    );
    setFilteredData(filteredData);
  };

  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    filterData(event.target.value);
  };

  return (
    <div className="App" style={{ width: "100%" }}>
      <div align="right">
        <input
          style={{
            width: "24%",
            borderRadius: "50px",
            marginRight: "400px !important",
            padding: "20px",
          }}
          type="text"
          placeholder="Search By Name"
          value={searchValue}
          onChange={handleSearch}
        />
        <Link to="/delete_doctor" style={{ marginRight: "4px" }}>
          <Button variant="primary">Doctor</Button>
        </Link>
        <Link to="/delete_company" style={{ marginRight: "4px" }}>
          <Button variant="primary">Company</Button>
        </Link>
        <Link to="/delete_employee" style={{ marginRight: "4px" }}>
          <Button variant="primary">Employee</Button>
        </Link>
        <Link to="/delete_offers" style={{ marginRight: "4px" }}>
          <Button variant="primary">Offers</Button>
        </Link>
        <Link to="/delete_tariff" style={{ marginRight: "4px" }}>
          <Button variant="primary">Tariff</Button>
        </Link>
        <Link to="/delete_ads" style={{ marginRight: "4px" }}>
          <Button variant="primary">Ads</Button>
        </Link>
        <Link to="/delete_mode" style={{ marginRight: "4px" }}>
          <Button variant="primary">Mode</Button>
        </Link>
      </div>
      {/* <div align="right"><Button variant="primary">Add</Button></div> */}
      {loading ? (
        <table className="loader">
          <tbody>
            <tr>
              <td></td>
            </tr>
          </tbody>
        </table>
      ) : (
        <DataTable
          title="Deleted Employees & Doctors"
          columns={columns}
          data={filteredData}
          pagination
          highlightOnHover
        />
      )}
    </div>
  );
}

export default DataSection;
