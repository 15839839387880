import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { Link } from "react-router-dom";
import axiosIns from "../components/axiosIns";
import { Alert } from "../components/Toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const styles = [
  `
  .bt_view{ height: 20px !important; margin-left: 15px; width: 20px !important; font-size: 14px; height: 30px; }
  .rdt_TableRow{ padding: 1%; }
`,
];
const addDropDown = (param) => {
  console.log(param);

  return (
    <>
      <div>
        {/* {/* <Link>
          <FontAwesomeIcon
            icon={faEye}
            style={{ color: "#50C878" }}
            className="bt_view"
          />
        </Link> */}
        <Link to={`/company_update/${param._id}`}>
          <FontAwesomeIcon
            icon={faEdit}
            style={{ color: "blue" }}
            className="bt_view"
          />
        </Link>
        <Link>
          <FontAwesomeIcon
            icon={faTrashAlt}
            onClick={handleDeleteAll.bind(this, param._id)}
            style={{ color: "red" }}
            className="bt_view"
          />
        </Link>
      </div>
    </>
  );
};

const handleDeleteAll = async (id) => {
  const choice = window.confirm(
    "Are you sure you want to delete this Company?"
  );
  if (choice) {
    await axiosIns
      .post("/company_delete", { id: id, status: 2 })
      .then((res) => {
        if (res.data.status) {
          Alert("succ", res.data.msg);
          window.location.reload(true);
        } else Alert("err", res.data.msg);
      })
      .catch((err) => {
        Alert("err", "Please Try Again");
      });
  }
};

const getProfile = (param) => {
  return (
    <>
      <img
        height={100}
        width={85}
        style={{ borderRadius: "10%" }}
        src={param.picture ? param.picture : "./images/noimg.jpg"}
      />
    </>
  );
};

const columns = [
  {
    name: "Name",
    selector: "company_name",
    sortable: true,
  },
  {
    name: "Profile",
    selector: (param) => getProfile(param),
    sortable: false,
  },
  {
    name: "Number Of Employee",
    selector: "no_of_emp",
    sortable: true,
  },
  {
    name: "Mobile",
    selector: "mobile",
    sortable: true,
  },
  {
    name: "Company Address",
    selector: "address",
    sortable: true,
  },
  {
    name: "Actions",
    selector: (param) => addDropDown(param),
  },
];

function Company() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const getData = async () => {
    await axiosIns
      .post("/company_list")
      .then((res) => {
        setData(res.data.data);
        setFilteredData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response.data.msg == "token") {
          localStorage.clear("isLogin");
          window.location.reload("/");
        }
        console.log(err.response);
      });
  };

  useEffect(() => {
    const checkTokenExpiration = setInterval(() => {
      const expirationTime = localStorage.getItem("tokenExpirationTime");
      if (expirationTime && Date.now() > parseInt(expirationTime)) {
        localStorage.clear("isLogin");
        window.location.reload("/");
      }
    }, 1000);

    clearInterval(checkTokenExpiration);
    getData();
  }, []);

  const [filteredData, setFilteredData] = useState(data);

  const filterData = (value) => {
    const lowerCaseValue = value.toLowerCase().trim();
    const filteredData = data.filter((item) =>
      item.company_name.toLowerCase().includes(lowerCaseValue)
    );
    setFilteredData(filteredData);
  };

  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    filterData(event.target.value);
  };

  return (
    <>
      <style>{styles}</style>
      <div className="App" style={{ width: "100%" }}>
        <div align="left">
          <input
            style={{
              width: "24%",
              borderRadius: "50px",
              marginRight: "770px",
              padding: "20px",
            }}
            type="text"
            placeholder="Search Company by Name"
            value={searchValue}
            onChange={handleSearch}
          />

          <Link to="/add_company">
            <Button variant="primary">Add</Button>
          </Link>
        </div>
        <br />
        {/* <div style={{ marginBottom: "10px" }}>
  
  </div> */}
        {loading ? (
          <table className="loader">
            <tbody>
              <tr>
                <td></td>
              </tr>
            </tbody>
          </table>
        ) : (
          <DataTable
            title="Company"
            columns={columns}
            data={filteredData}
            pagination
            highlightOnHover
          />
        )}
      </div>
    </>
  );
}

export default Company;
