import React, { useState, useEffect } from "react";
import "../register.css";
import axiosIns from "../../components/axiosIns";
import { useParams } from "react-router-dom";
import { Alert } from "../../components/Toastify";

const BookAppointment = () => {
  const [employeeData, setData] = useState({
    address: "",
    picture: "",
    company_name: "",
    employee_id: "",
    gender: "",
    mobile: "",
    marital_status: "",
    age: "",
    name: "",
    job_role: "",
    service_id: "",
    doctor_id: "",
    family_details: [],
    date: "",
    appointmentFor:"",
    description : "",
  });
  const {
    picture,
    employee_id,
    job_role,
    address,
    mobile,
    marital_status,
    gender,
    name,
    age,
    service_id,
    doctor_id,
    family_details,
    date,
    appointmentFor,
    description,
  } = employeeData;
 
  const [showLoader, setShowLoader] = useState(false);

  const handleChange = (e) => {
    setData({ ...employeeData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setShowLoader(true);
    const datapara = {
      "_id": employeeData._id,
      "service_id" : employeeData.service_id,
      "doctor_id" : employeeData.doctor_id,
      "date" : employeeData.date,
      "appointment_for" : employeeData.appointmentFor,
      "description" : employeeData.description,
      "family_id" : employeeData.family_id,
    };
    await axiosIns
      .post("/booking_appointment", datapara)
      .then((res) => {
        if (res.data.status === true) {
          Alert("succ",res.data.msg);
          window.location.replace("/employee");
          setShowLoader(false);
        } else Alert("warn",res.data.msg);
        setShowLoader(false);
      })
      .catch((err) => {
        Alert("error","Please Try Again...");
        setShowLoader(false);
      });
  };

  const [doctorList, setDoctorList] = useState([]);
  const [servicelist, setService] = useState([]);
  const getData = async () => {
    await axiosIns
      .post("/service_list")
      .then((res) => {
        setService(res.data.data);
      })
      .catch((err) => {
        if (err.response.data.msg == "token") {
          localStorage.clear("isLogin");
          window.location.reload("/");
        }
        console.log(err.response);
      });
  };
  const params = useParams();
  const getempdetails = async () => {
    if (params.id) {
      await axiosIns
        .post("/get_employee", { id: params.id })
        .then((res) => {
          setData(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else window.location.replace("/");
  };
  const handleService = async (e) => {
    var ser_id = e.target.value;
    setData({ ...employeeData, [e.target.name]: ser_id });
    if (ser_id) {
      await axiosIns
        .post("/get_service_doctor", { id: ser_id })
        .then((res) => {
          setDoctorList(res.data.data);
        })
        .catch((err) => {
          setDoctorList([]);
        });
    } else setDoctorList([]);
  };

  useEffect(() => {
    const checkTokenExpiration = setInterval(() => {
      const expirationTime = localStorage.getItem("tokenExpirationTime");
      if (expirationTime && Date.now() > parseInt(expirationTime)) {
        localStorage.clear("isLogin");
        window.location.reload("/");
      }
    }, 1000);

      clearInterval(checkTokenExpiration);
      getData();
      getempdetails();
  }, []);

  const service = servicelist.map((option) => {
    return (
      <option key={option._id} value={option._id}>
        {option.name}
      </option>
    );
  });
  const family = family_details.map((option, i) => {
    return (
      <option key={i} value={option._id}>
        {option.relation_name}
      </option>
    );
  });
  

  const doclist = doctorList.map((row) => {
    return (
      <option key={row._id} value={row._id}>
        {row.name}
      </option>
    );
  });
  return (
    <>
      <div className="container">
        <div className="row">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <h1 style={{ alignItems: "center" }}>Book Appointment</h1>
              <div className="col-md-4">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  readOnly={true}
                  onChange={handleChange}
                  placeholder="Name"
                  name="name"
                />
              </div>
              <div className="col-md-4">
                <label>Age</label>
                <input
                  type="text"
                  className="form-control"
                  value={age}
                  readOnly={true}
                  onChange={handleChange}
                  placeholder="Age"
                  name="age"
                />
              </div>
              <div className="col-md-4">
                <label>Gender</label>
                <input
                  type="text"
                  className="form-control"
                  value={gender}
                  readOnly={true}
                  onChange={handleChange}
                  placeholder="Gender"
                  name="gender"
                />
              </div>
              <div className="col-md-4">
                <label>Employee Id</label>
                <input
                  type="text"
                  className="form-control"
                  value={employee_id}
                  readOnly={true}
                  onChange={handleChange}
                  placeholder="Employee ID"
                  name="employee_id"
                />
              </div>
              <div className="col-md-4">
                <label>Designation</label>
                <input
                  type="text"
                  className="form-control"
                  value={job_role}
                  readOnly={true}
                  onChange={handleChange}
                  placeholder="Job Role"
                  name="job_role"
                />
              </div>
              <div className="col-md-4">
                <label>Mobile Number</label>
                <input
                  type="text"
                  className="form-control"
                  value={mobile}
                  readOnly={true}
                  onChange={handleChange}
                  placeholder="Mobile Number"
                  name="mobile"
                />
              </div>
              <div className="col-md-4">
                <label>Address</label>
                <input
                  type="text"
                  className="form-control"
                  value={address}
                  readOnly={true}
                  onChange={handleChange}
                  placeholder="Address"
                  name="address"
                />
              </div>
              <div className="col-md-4">
              <label>Appointment For</label>
              <div>
                <select
                  value={appointmentFor}
                  onChange={handleChange}
                  name="appointmentFor"
                >
                  <option value="">--Select--</option>
                  <option value="1">Myself</option>
                  <option value="2">Family</option>
                </select>
              </div>
            </div>
            {appointmentFor === "2" && (
              <div className="col-md-4">
                <label>Family Members</label>
                <div>
                  <select
                    onChange={handleChange}
                    name="family_id"
                    multiple={false}
                  >
                    <option value="">--Select--</option>
                     {family}
                  </select>
                </div>
              </div>
            )}

              <div className="col-md-4">
                <label>Service</label>
                <select
                  className="form-control"
                  value={service_id}
                  name="service_id"
                  onChange={handleService}
                >
                  <option value="">select</option>
                  {service}
                </select>
              </div>
              <div className="col-md-4">
                <label>Consultant</label>
                <select
                  className="form-control"
                  value={doctor_id}
                  name="doctor_id"
                  onChange={handleChange}
                >
                  <option value="">select</option>
                  {doclist}
                </select>
              </div>
              <div className="col-md-4">
                <label>Date</label>
                <input
                  type="date"
                  className="form-control"
                  value={date}
                  onChange={handleChange}
                  placeholder="Enter Your Date"
                  name="date"
                />
              </div>
              <div
                className="col-md-12"
                align="center"
                style={{ padding: "10px" }}
              >
                <button
                  onClick={handleSubmit}
                  type="button"
                  className="btn btn-success" disabled={showLoader}>
                  {showLoader ? (
                      <div className="spinner-border spinner-border-sm" role="status"
                      aria-hidden="true"></div>
                    ) : ( 
                      "Save"
                    )
                  }
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      
    </>
  );
};

export default BookAppointment;
