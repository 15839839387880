import React, { useState, useEffect } from "react";
import "../register.css";
import axiosIns from "../../components/axiosIns";
import { storage } from "../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";
import { Alert } from "../../components/Toastify";
import { useParams } from "react-router";


const AddEmployee = () => {
  const params = useParams();
  const [showLoader, setShowLoader] = useState(false);
  const [employeeData, setData] = useState({
    address: "",
    password: "123",
    company_name: "",
    employee_id: "",
    gender: "",
    mobile: "",
    marital_status: "",
    email: "",
    age: "",
    name: "",
    job_role: "",
    picture: "",
    family_picture: "",
  });
  const {
    company_name,
    employee_id,
    job_role,
    address,
    mobile,
    marital_status,
    gender,
    email,
    name,
    age,
    picture,
    family_picture,
  } = employeeData;

  const handleChange = (e) => {
    setData({ ...employeeData, [e.target.name]: e.target.value });
    console.log(employeeData);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setShowLoader(true);
    //Employee Picture Upload
    var promises = [];
    if (employeeProfile == null) {
      Alert("warn", "Please Choose File");
      setShowLoader(false);
    } else {
      if (profileCheck == null) {
        const imageRef = ref(
          storage,
          `employee/profile/${employeeProfile.name + v4()}`
        );
        await uploadBytes(imageRef, employeeProfile).then((res) => {
          return getDownloadURL(ref(storage, res.metadata.fullPath)).then(
            (url) => {
              employeeData.picture = url;
              setData(employeeData);
              setPicCheck(employeeProfile);
            }
          );
        });
      } else {
        if (profileCheck.name !== employeeProfile.name) {
          const imageRef = ref(
            storage,
            `employee/profile/${employeeProfile.name + v4()}`
          );
          await uploadBytes(imageRef, employeeProfile).then((res) => {
            return getDownloadURL(ref(storage, res.metadata.fullPath)).then(
              (url) => {
                employeeData.picture = url;
                setData(employeeData);
                setPicCheck(employeeProfile);
              }
            );
          });
        }
      }
    }

    //Family Profile Upload
    if (familyProfile == null) {
      setShowLoader(false);
      Alert("warn", "Please Choose File");
    } else {
      if (familyprofileCheck == null) {
        const imageRef = ref(
          storage,
          `employee/family/${familyProfile.name + v4()}`
        );
        await uploadBytes(imageRef, familyProfile).then((res) => {
          return getDownloadURL(ref(storage, res.metadata.fullPath)).then(
            (url) => {
              employeeData.family_picture = url;
              setData(employeeData);
              setFamilyPicCheck(familyProfile);
            }
          );
        });
      } else {
        if (familyprofileCheck.name !== familyProfile.name) {
          const imageRef = ref(
            storage,
            `employee/family/${familyProfile.name + v4()}`
          );
          await uploadBytes(imageRef, familyProfile).then((res) => {
            return getDownloadURL(ref(storage, res.metadata.fullPath)).then(
              (url) => {
                employeeData.family_picture = url;
                setData(employeeData);
                setFamilyPicCheck(familyProfile);
              }
            );
          });
        }
      }
    }

    if (params.id) var url = "/employee_update";
    else var url = "/employee_register";
    await axiosIns
      .post(url, employeeData)
      .then((res) => {
        if (res.data.status === true) {
          Alert("succ", res.data.msg);
          setShowLoader(false);
          window.location.replace("/employee");
        } else Alert("warn", res.data.msg);
        setShowLoader(false);
      })
      .catch((err) => {
        Alert("error", "Please Try Again...");
        setShowLoader(false);
      });
  };

  const [companylist, setcompanyData] = useState([]);
  const [employeeProfile, setProfile] = useState(null);
  const [profileCheck, setPicCheck] = useState(null);
  const [familyProfile, setFamilyProfile] = useState(null);
  const [familyprofileCheck, setFamilyPicCheck] = useState(null);

  const getData = async () => {
    await axiosIns
      .post("/company_list")
      .then((res) => {
        setcompanyData(res.data.data);
        console.log(companylist);
        // console.log(employeeData);
      })
      .catch((err) => {
        if (err.response.data.msg == "token") {
          localStorage.clear("isLogin");
          window.location.reload("/");
        }
        console.log(err.response);
      });
  };

  useEffect(() => {
    const checkTokenExpiration = setInterval(() => {
      const expirationTime = localStorage.getItem("tokenExpirationTime");
      if (expirationTime && Date.now() > parseInt(expirationTime)) {
        localStorage.clear("isLogin");
        window.location.reload("/");
      }
    }, 1000);

    clearInterval(checkTokenExpiration);
    getData();
    if (params.id) {
      get_employee_details();
    }
  }, []);

  const com_list = companylist.map((option) => {
    return (
      <option key={option._id} value={option._id}>
        {option.company_name}
      </option>
    );
  });

  const [inputList, setinputList] = useState([
    { relation_name: "", relationship: "", relation_age: "" },
  ]);

  const handleinputChange = (e, i) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[i][name] = value;
    setinputList(list);
    setData({ ...employeeData, ["family_details"]: inputList });
  };

  const handleaddclick = () => {
    setinputList([
      ...inputList,
      { relation_name: "", relationship: "", relation_age: "" },
    ]);
  };
  const handleClick = async (i, paramId) => {
    console.log(i + paramId);
    if (paramId) {
      const choice = window.confirm(
        "Are you sure you want to delete this Relation Record completely?"
      )
      if (choice){
        await axiosIns.post('/relation_delete', {id: paramId, status : 2}).then((res)=>{
          if(res.data.status) { Alert("succ", res.data.msg); handleremove(i); }
          else Alert("err", res.data.msg);
        }).catch((err)=>{
          Alert("err", "Please Try Again");
        })
      }
    } else {
      // Remove the record directly from the UI
      handleremove(i);
    }
  };
  
  const handleremove = (i) => {
    const list = [...inputList];
    list.splice(i, 1);
    setinputList(list);
    setData({ ...employeeData, ["family_details"]: inputList });
  };

  const get_employee_details = async () => {
    await axiosIns
      .post("/get_employee", { id: params.id })
      .then((res) => {
        employeeData.id = res.data.data._id;
        employeeData.name = res.data.data.name;
        employeeData.picture = res.data.data.picture;
        employeeData.family_picture = res.data.data.family_picture;
        employeeData.address = res.data.data.address;
        employeeData.company_name = res.data.data.company_name;
        employeeData.gender = res.data.data.gender;
        employeeData.employee_id = res.data.data.employee_id;
        employeeData.mobile = res.data.data.mobile;
        employeeData.email = res.data.data.email;
        employeeData.marital_status = res.data.data.marital_status;
        employeeData.age = res.data.data.age;
        employeeData.job_role = res.data.data.job_role;
        setData({ ...employeeData });
        setProfile(res.data.data.picture);
        setPicCheck(res.data.data.picture);
        setFamilyProfile(res.data.data.family_picture);
        setFamilyPicCheck(res.data.data.family_picture);
        if (res.data.data.family_details.length > 0)
          setinputList(res.data.data.family_details);
        console.log(employeeProfile);
        console.log(profileCheck);
      })
      .catch((err) => {
        if (err.response.data.msg == "token") {
          localStorage.clear("isLogin");
          window.location.reload("/");
        } else Alert("error", "Please Try Again...");
        setShowLoader(false);
      });
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <h1 style={{ alignItems: "center" }}>Add Employee</h1>
              <div className="col-md-4">
                <label>
                  Name <span className="span">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={handleChange}
                  placeholder="Name"
                  name="name"
                />
              </div>
              <div className="col-md-4">
                <label>
                  Age <span className="span">*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  value={age}
                  onChange={handleChange}
                  placeholder="Age"
                  name="age"
                />
              </div>
              <div className="col-md-4">
                <label>
                  Gender <span className="span">*</span>
                </label>
                <select
                  className="form-control"
                  value={gender}
                  onChange={handleChange}
                  placeholder="Gender"
                  name="gender"
                >
                  <option key="0" value="">
                    --Select--
                  </option>
                  <option key="1" value="Male">
                    Male
                  </option>
                  <option key="2" value="Female">
                    Female
                  </option>
                  <option key="3" value="Others">
                    Others
                  </option>
                </select>
              </div>
              <div className="col-md-4">
                <label>
                  Employee Id <span className="span">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={employee_id}
                  onChange={handleChange}
                  placeholder="Employee ID"
                  name="employee_id"
                />
              </div>
              <div className="col-md-4">
                <label>
                  Job Role <span className="span">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={job_role}
                  onChange={handleChange}
                  placeholder="Job Role"
                  name="job_role"
                />
              </div>
              <div className="col-md-4">
                <label>
                  Mobile Number <span className="span">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={mobile}
                  onChange={handleChange}
                  placeholder="Mobile Number"
                  name="mobile"
                />
              </div>
              <div className="col-md-4">
                <label>
                  Mail Id <span className="span">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={email}
                  onChange={handleChange}
                  placeholder="Email Id"
                  name="email"
                />
              </div>
              <div className="col-md-4">
                <label>
                  Company Name <span className="span">*</span>
                </label>
                <select
                  className="form-control"
                  value={company_name}
                  onChange={handleChange}
                  name="company_name"
                >
                  <option>--Select--</option>
                  {com_list}
                </select>
              </div>
              <div className="col-md-4">
                <label>
                  Marital Status <span className="span">*</span>
                </label>
                <select
                  className="form-control"
                  value={marital_status}
                  onChange={handleChange}
                  placeholder="Marital Status"
                  name="marital_status"
                >
                  <option value="">--Select--</option>
                  <option value="Single">Single</option>
                  <option value="Married">Married</option>
                </select>
              </div>
              <div className="col-md-4">
                <label>
                  Address <span className="span">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={address}
                  onChange={handleChange}
                  placeholder="Address"
                  name="address"
                />
              </div>
              <div className="col-md-4">
                <label>
                  Employee Profile <sapn>(155*200)</sapn>
                  <span className="span">*</span>
                </label>
                <input
                  type="file"
                  className="form-control"
                  onChange={(e) => {
                    setProfile(e.target.files[0]);
                  }}
                  placeholder="Age"
                  name="picture"
                />
                <img
                  src={picture ? picture : "/images/noimg.jpg"}
                  width={155}
                  height={200}
                />
              </div>
              <div className="col-md-4">
                <label>
                  Family Picture <sapn>(375*200)</sapn>
                  <span className="span">*</span>
                </label>
                <input
                  type="file"
                  className="form-control"
                  onChange={(e) => {
                    setFamilyProfile(e.target.files[0]);
                  }}
                  placeholder="Age"
                  name="family_picture"
                />
                <img
                  src={family_picture ? family_picture : "/images/noimg.jpg"}
                  width={375}
                  height={200}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <label>Name</label>
              </div>
              <div className="col-md-3">
                <label>RelationShip</label>
              </div>
              <div className="col-md-3">
                <label>Age</label>
              </div>
              <div className="col-md-3"></div>
            </div>
            {inputList.map((x, i) => {
              return (
                <div className="row" key={i}>
                  <div className="col-md-3">
                    <input
                      type="text"
                      name="relation_name"
                      className="form-control"
                      value={x.relation_name}
                      onChange={(e) => handleinputChange(e, i)}
                    />
                  </div>
                  <div className="col-md-3">
                    <input
                      type="text"
                      name="relationship"
                      className="form-control"
                      value={x.relationship}
                      onChange={(e) => handleinputChange(e, i)}
                    />
                  </div>
                  <div className="col-md-3">
                    <input
                      type="text"
                      name="relation_age"
                      className="form-control"
                      value={x.relation_age}
                      onChange={(e) => handleinputChange(e, i)}
                    />
                  </div>
                  <div className="col-md-3">
                    {inputList.length !== 1 && (
                      <label
                        className="btn btn-danger"
                        onClick={() => handleClick(i, x._id)}
                      >
                        -
                      </label>
                    )}
                    {inputList.length - 1 === i && (
                      <label
                        className="btn btn-primary"
                        onClick={handleaddclick}
                      >
                        <i className="fa fa-plus"></i>
                      </label>
                    )}
                  </div>
                </div>
              );
            })}
            <div className="row">
              <div
                className="col-md-12"
                align="center"
                style={{ padding: "10px" }}
              >
                <button
                  onClick={handleSubmit}
                  type="button"
                  className="btn btn-success"
                  disabled={showLoader}
                >
                  {showLoader ? (
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></div>
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddEmployee;
