import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
} from "mdb-react-ui-kit";

export default function RR() {
  const styles = `
      p {
        font-size: 14px !important;
      }
      .row{
        margin-top: 10px !important;
        margin-bottom: 10px !important;
      }
      .marquee {
        font-size: 24px;
        color: #06603a;
        margin-bottom : 10px;
      }
    `;
  
  

    // const params = useParams();
  return (
    <section>
      <style>{styles}</style>
      <h2 style={{textAlign:"center"}}>RR Hospital</h2>
      <MDBContainer className="py-5">
        <MDBRow>
          <MDBCol lg="8" className="mx-auto">
            <MDBCard className="mb-4">
              <MDBCardBody className="text-center">
                <MDBCardImage
                  src={"/images/RRHospital.jpg"}
                  alt="avatar"
                  style={{ width: "900px", height: "500px" }}
                  fluid
                />
              </MDBCardBody>
            </MDBCard>
          </MDBCol>&nbsp;
          <MDBCol lg="8" className="mx-auto">
            <MDBCard>
              <MDBRow>
              <h5 style={{fontSize:"1.5rem",fontWeight:"800",lineHeight:"20px", textAlign:"center"}}>Hospital Address</h5>
                &nbsp;
                <p style={{textAlign:"center"}}> FH35+7JQ, Kamaraj Nagar 4th St, Rajapalayam, Tamil Nadu 626117</p>
              </MDBRow>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </section>
  );
}
