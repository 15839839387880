import React from "react";
import "./App.css";
import Sidebar from "./components/Sidebar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Company from "./pages/Company";
import Request from "./pages/Request";
import Doctor from "./pages/Doctor";
import DataSection from "./pages/DataSection";
import Offers from "./pages/Offers";
import TariffTab from "./pages/TariffTab";
import Dashboard from "./pages/Dashboard";
import Login from "./Login";
import Adddetail from "./pages/Adddetails";
import AddDoctor from "./pages/Doctor/AddDoctor";
import AddCompany from "./pages/company/AddCompany";
import Employee from "./pages/Employee";
import AddEmployee from "./pages/Employee/AddEmployee";
import BookAppointment from "./pages/Employee/BookAppointment";
import EmployeeProfile from "./pages/Employee/EmployeeProfile";
import Logout from "./logout";
import Ads from "./pages/Ads";
import AddAds from "./pages/AddAds";
import AddService from "./pages/AddService";
import BulkAdd from "./pages/Employee/BulkAdd";
import { ToastContainer } from "react-toastify";
import AddOffers from "./pages/AddOffers";
import ServiceList from "./pages/ServiceList";
import DeletedDoctor from "./pages/DeletedList/DeletedDoctor";
import DeletedCompany from "./pages/DeletedList/DeletedCompany";
import DeletedEmployee from "./pages/DeletedList/DeletedEmployee";
import DeletedOffers from "./pages/DeletedList/DeletedOffers";
import DeletedAds from "./pages/DeletedList/DeletedAds";
import DeletedTariff from "./pages/DeletedList/DeletedTariff";
import DeletedMode from "./pages/DeletedList/DeletedMode";
import TariffList from "./pages/TariffList";
import TariffList1 from "./pages/TariffList1";
import ForgotPassword from "./pages/ForgotPassword";
import NewPassword from "./pages/NewPassword";
import NotFound from "./components/404";
import Report from "./Report";
import HospitalList from "./pages/HospitalList";
import Arputham from "./pages/NetworkHospital/Arputham";
import RR from "./pages/NetworkHospital/RR";
import Malar from "./pages/NetworkHospital/Malar";
import DiyaHeart from "./pages/NetworkHospital/DiyaHeart";
import Rajeswari from "./pages/NetworkHospital/Rajeswari";
import Sun from "./pages/NetworkHospital/Sunscans";
import Santo from "./pages/NetworkHospital/Santo";
import Radhu from "./pages/NetworkHospital/Radhu";
import Bedasta from "./pages/NetworkHospital/Bedesta";
import Genga from "./pages/NetworkHospital/Gengadental";
import Annai from "./pages/NetworkHospital/Annai";

function App() {
  const isLogin = localStorage.getItem("isLogin");
  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route exact path="/login" element={<Login />} />
          <Route
            path="/"
            exact
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <Dashboard />{" "}
                  </Sidebar>
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/doctor"
            exact
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <Doctor />
                  </Sidebar>
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/company"
            exact
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <Company />
                  </Sidebar>
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/employee"
            exact
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <Employee />
                  </Sidebar>
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/request"
            exact
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <Request />
                  </Sidebar>
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/deleted_data"
            exact
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <DataSection />
                  </Sidebar>
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/offers"
            exact
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <Offers />
                  </Sidebar>
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/ads"
            exact
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <Ads />
                  </Sidebar>
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/tariffTab"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <TariffTab />
                  </Sidebar>
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/tarifflist"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <TariffList />
                  </Sidebar>
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/tarifflist1"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <TariffList1 />
                  </Sidebar>
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/book_appointment/:id"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <BookAppointment />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/employee_profile/:id"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <EmployeeProfile />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/add"
            exact
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <Adddetail />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/add_doctor"
            exact
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <AddDoctor />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/add_offers"
            exact
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <AddOffers />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
           <Route
            path="/networkhospitals"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                   <HospitalList />
                  </Sidebar>
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/add_company"
            exact
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <AddCompany />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/add_employee"
            exact
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <AddEmployee />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/add_ads"
            exact
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <AddAds />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/service"
            exact
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <ServiceList />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/add_service"
            exact
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <AddService />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/bulk_add"
            exact
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <BulkAdd />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/logout"
            exact
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <Logout />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          {/* <Route
            path="/delete_doctor"
            exact
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <DeletedDoctor />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route> */}
          <Route path="/delete_doctor" element= { isLogin ? <> <Sidebar> <DeletedDoctor /></Sidebar> </> : <Login/>}></Route>
          <Route path="/delete_company" element= { isLogin ? <> <Sidebar> <DeletedCompany /></Sidebar> </> : <Login/>}></Route>
          <Route path="/delete_employee" element= { isLogin ? <> <Sidebar> <DeletedEmployee /></Sidebar> </> : <Login/>}></Route>
          <Route path="/delete_offers" element= { isLogin ? <> <Sidebar> <DeletedOffers /></Sidebar> </> : <Login/>}></Route>
          <Route path="/delete_ads" element= { isLogin ? <> <Sidebar> <DeletedAds /></Sidebar> </> : <Login/>}></Route>
          <Route path="/delete_tariff" element= { isLogin ? <> <Sidebar> <DeletedTariff /></Sidebar> </> : <Login/>}></Route>
          <Route path="/delete_mode" element= { isLogin ? <> <Sidebar> <DeletedMode /></Sidebar> </> : <Login/>}></Route>
          <Route path="/employee_update/:id" element={ isLogin ? ( <> <Sidebar> <AddEmployee /> </Sidebar> </> ) : ( <Login /> ) } ></Route>
          <Route path="/ads_update/:id" element={ isLogin ? ( <> <Sidebar> <AddAds /> </Sidebar> </> ) : ( <Login /> ) } ></Route>
          <Route path="/service_update/:id" element={ isLogin ? ( <> <Sidebar> <AddService /> </Sidebar> </> ) : ( <Login /> ) } ></Route>
          <Route path="/offer_update/:id" element={ isLogin ? ( <> <Sidebar> <AddOffers /> </Sidebar> </> ) : ( <Login /> ) } ></Route>
          <Route path="/tariff_update/:id" element={ isLogin ? ( <> <Sidebar> <TariffTab /> </Sidebar> </> ) : ( <Login /> ) } ></Route>
          <Route path="/doctor_update/:id" element={ isLogin ? ( <> <Sidebar> <AddDoctor /> </Sidebar> </> ) : ( <Login /> ) } ></Route>
          <Route path="/company_update/:id" element={ isLogin ? ( <> <Sidebar> <AddCompany /> </Sidebar> </> ) : ( <Login /> ) } ></Route>
          <Route path="/forgot_password" element= { <ForgotPassword/> }></Route>
          <Route path="/new_password/:token" element= { <NewPassword/> }></Route>
          <Route path="*" element= { <NotFound/> }></Route>
          <Route path="/report" element= { isLogin ? <> <Sidebar> <Report /></Sidebar> </> : <Login/>}></Route>
          <Route
            path="/Arputham Hospital"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                  <Arputham />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/RR Hospital"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                     <RR />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/Malar Hospital"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <Malar />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/Diya Heart Center"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                     <DiyaHeart />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/Rajashwari Hospital"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                  <Rajeswari />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/Sun Scans"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                <Sun />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/Santho Scans"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <Santo />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/Radhu Hospital"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <Radhu />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/Bedasta Clinic"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                   <Bedasta />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/Genga Dental Care"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                     <Genga />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/Annai Eye Clinic"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <Annai />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}
//dd
export default App;
