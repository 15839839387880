import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
// import data from '../data.json';
// import Button from 'react-bootstrap/Button';
import "bootstrap/dist/css/bootstrap.min.css";
import axiosIns from "../components/axiosIns";
const getProfile = (param) => {
  return (
    <>
      <p>{param.appointment_for === "1" ? "Myself" : "Family"}</p>
    </>
  );
};

const columns = [
  {
    name: "Name",
    selector: (row) => (
      <div>
        {row.emp_ename}
        <br /> ({row.company})
      </div>
    ),
    sortable: true,
  },
  {
    name: "Appointment For",
    selector: (param) => getProfile(param),
    sortable: true,
  },
  {
    name : "Patient Name",
    selector : (row) => row.patient_name,
    sortable : true,
  },
  {
    name: "Service",
    selector: (row) => row.service,
    sortable: true,
  },
  {
    name: "Doctor",
    selector: (row) => row.doctor,
    sortable: true,
  },
  // {
  //   name: 'Requests & Notifications',
  //   selector: 'request',
  // },
  {
    name: "Date",
    selector: (param) => new Date(param.date).toLocaleDateString(),
    sortable: true,
  },
];

function Request() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    await axiosIns
      .post("/booking_request_list")
      .then((res) => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response.data.msg == "token") {
          localStorage.clear("isLogin");
          window.location.reload("/");
        }
      });
  };

  useEffect(() => {
    const checkTokenExpiration = setInterval(() => {
      const expirationTime = localStorage.getItem("tokenExpirationTime");
      if (expirationTime && Date.now() > parseInt(expirationTime)) {
        localStorage.clear("isLogin");
        window.location.reload("/");
      }
    }, 1000);

    clearInterval(checkTokenExpiration);
    getData();
  }, []);
  return (
    <div className="App" style={{ width: "100%" }}>
      {loading ? (
        <table className="loader">
          <tbody>
            <tr>
              <td></td>
            </tr>
          </tbody>
        </table>
      ) : (
        <DataTable
          title="Mail Request"
          columns={columns}
          data={data}
          pagination
          highlightOnHover
        />
      )}
    </div>
  );
}

export default Request;
