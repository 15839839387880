import React, { useState,useEffect } from "react";
import "../offers.css";
import { useParams } from "react-router";
import axiosIns from "../components/axiosIns";
import { storage } from "../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";
import { Alert } from "../components/Toastify";

const AddAds = () => {
  const [offerData, setOfferData] = useState({
    date: "",
    picture: "",
  });

  const { date, picture } = offerData;
  const handleChange = (event) => {
    console.log(event.target.name);
    setOfferData({ ...offerData, [event.target.name]: event.target.value });
  };


  const [offerPic, setProfile] = useState(null);
  const [profileCheck, setPicCheck] = useState(null);
  const [showLoader, setShowLoader] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setShowLoader(true);
    // setShowSuccessMsg(true);
    if (offerPic == null) {
      Alert("error", "Please Choose File");
      setShowLoader(false);
      return false;
    } else {
      if (profileCheck == null) {
        const imageRef = ref(storage, `ads/${offerPic.name + v4()}`);
        await uploadBytes(imageRef, offerPic).then((res) => {
          return getDownloadURL(ref(storage, res.metadata.fullPath)).then(
            (url) => {
              offerData.picture = url;
              setOfferData(offerData);
              setPicCheck(offerPic);
            }
          );
        });
      } else {
        if (profileCheck.name != offerPic.name) {
          const imageRef = ref(storage, `ads/${offerPic.name + v4()}`);
          await uploadBytes(imageRef, offerPic).then((res) => {
            return getDownloadURL(ref(storage, res.metadata.fullPath)).then(
              (url) => {
                offerData.picture = url;
                setOfferData(offerData);
                setPicCheck(offerPic);
              }
            );
          });
        }
      }
    }

    await axiosIns
      .post("/add_ads", offerData)
      .then((res) => {
        if (res.data.status === true) {
          Alert ("succ",res.data.msg);
          window.location.reload("/ads");
        } else Alert('warn', res.data.msg);
        setShowLoader(false);
      })
      .catch((err) => {
        if (err.response.data.msg == "token") {
          localStorage.clear("isLogin");
          window.location.reload("/");
        } else
        Alert ("error", "Please Try Again...");
        setShowLoader(false);
        console.log(err.response);
      });
  };
  const params = useParams();

  useEffect(()=>{
    const checkTokenExpiration = setInterval(() => {
          const expirationTime = localStorage.getItem("tokenExpirationTime");
          if (expirationTime && Date.now() > parseInt(expirationTime)) {
            localStorage.clear("isLogin");
            window.location.reload("/");
          }
        }, 1000);
    
          clearInterval(checkTokenExpiration);
        if (params.id) {
          get_ads_details();
        }
    }, []);

    const get_ads_details = async()=>{
      await axiosIns
        .post("/get_ads", { id: params.id })
        .then((res) => {
          offerData.id = res.data.data._id;
          offerData.picture = res.data.data.picture;
          const date = new Date(res.data.data.date);
          const year = date.getUTCFullYear();
          const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
          const day = date.getUTCDate().toString().padStart(2, '0');
          const formattedDate = `${day}-${month}-${year}`;
          offerData.date = formattedDate;
          setOfferData({...offerData}); 
          setProfile(res.data.data.picture);
          setPicCheck(res.data.data.picture);
          console.log(offerPic)
          console.log(profileCheck)
        }).catch((err) => {  
          if (err.response.data.msg == "token") {
            localStorage.clear("isLogin");
            window.location.reload("/");
          } else
            Alert ("error", "Please Try Again...");
            setShowLoader(false);
        });
    }

  return (
    <div className="container">
      <div className="row">
        <h1>Add Ads</h1>
        <div className="col-md-6">
          <div className="image-boxs">
            <label htmlFor="img">
              Picture <sapn>(300*300)</sapn>
            </label>
            <input
              type="file"
              className="form-control"
              onChange={(e) => {
                setProfile(e.target.files[0]);
              }}
            />
            <img src={picture ? picture : "/images/noimg.jpg"} width={300} height={300} />
          </div>
        </div>
        <div className="col-md-6">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="title">
                Date<span className="span">*</span>
              </label>
              <input
                type="date"
                className="form-control"
                id="date"
                placeholder=""
                value={date}
                name="date"
                onChange={handleChange}
                required
              />
            </div>
            <div className="buttons-container">
              <button type="submit" className="upload-button"  disabled={showLoader}>
                {showLoader ? (
                  <div className="spinner-border spinner-border-sm" role="status"
                  aria-hidden="true"></div>
                ) : ( 
                  "Upload"
                )
              }
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddAds;
