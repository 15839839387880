import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import axiosIns from "../components/axiosIns";
import { Alert } from "../components/Toastify";

const styles = [`
.forgot-password-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f7fffd;
  }
  
  .forgot-password-form {
    background-color: #f7fffd;
    padding: 20px;
    border-radius: 5px;
    width: 300px;
    text-align: center;
  }
  
  .forgot-password-form h2 {
    margin-top: 0;
  }
  
  .forgot-password-form form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .forgot-password-form input[type="email"] {
    height: 30px;
    font-size : 14px;
    padding: 5px;
  }
  
  .forgot-password-form button[type="submit"] {
    background-color: #6cf0c2;
    border: none;
    color: #fff;
    padding: 10px 15px;
    cursor: pointer;
  }
  
  .forgot-password-form button[type="submit"]:hover {
    background-color: #5ab6a8;
  }
  
  .forgot-password-form button[type="submit"]:focus {
    outline: none;
  }
  
  .forgot-password-form button[type="submit"]:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
`]

function ForgotPassword() {
  const [email, setEmail] = useState("");

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const REACT_APP_URL = process.env.REACT_APP_URL;
    const data = { email };
    try {
      const res = await axiosIns.post(`${REACT_APP_URL}/forget_password`, data);
      if (res.data.status === true) {
        Alert("succ", res.data.msg);
      } else {
        Alert("error", res.data.msg);
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        Alert("error", "User not found.");
      } else {
        console.log(error);
        Alert("error", "An error occurred. Please try again.");
      }
    }
  };

  return (
    <>
      <style>{styles}</style>
      <div className="forgot-password-page">
        <div className="forgot-password-form">
          <h2>Forgot Password</h2><br/><br/>
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              name="email"
              value={email}
              onChange={handleChange}
              placeholder="Enter your email"
              required
            />
            <br/>
            <button type="submit">Submit</button>
          </form>
          <Link to="/login">
            <span style={{ display: "flex", alignItems: "center", marginTop: "10px", fontSize : "14px" }}>
              <FiArrowLeft style={{ marginRight: "5px" }} />
              Back to Login
            </span>
          </Link>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
