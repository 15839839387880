import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import './Hospitallist.css';

const HospitalList = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const hospitals = [
    'Arputham Hospital',
    'RR Hospital',
    'Malar Hospital',
    'Diya Heart Center',
    'Rajashwari Hospital',
    'Sun Scans',
    'Santho Scans',
    'Radhu Hospital',
    'Bedasta Clinic',
    'Genga Dental Care',
    'Annai Eye Clinic'
  ];

  const inputStyle = {
    marginBottom: '10px',
    padding: '5px',
    fontSize: '16px',
    borderRadius: '20px',
    width: '200px',
  };

  const tableStyle = {
    marginTop: '20px',
    fontSize: '14px',
  };

  // Divide hospitals into groups of 3
  const groupedHospitals = [];
  for (let i = 0; i < hospitals.length; i += 3) {
    groupedHospitals.push(hospitals.slice(i, i + 3));
  }

  const columns = [
    {
      selector: row => row[0],
      sortable: true,
      cell: row => (
        <Link to={`/${encodeURIComponent(row[0])}`} className="link-style">
          {row[0]}
        </Link>
      ),
    },
    {
      selector: row => row[1],
      sortable: true,
      cell: row => (
        <Link to={`/${encodeURIComponent(row[1])}`} className="link-style">
          {row[1]}
        </Link>
      ),
    },
    {
      selector: row => row[2],
      sortable: true,
      cell: row => (
        <Link to={`/${encodeURIComponent(row[2])}`} className="link-style">
          {row[2]}
        </Link>
      ),
    },
  ];

  const filteredHospitals = groupedHospitals.filter(group =>
    group.some(hospital =>
      hospital.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );
  
  const customStyles = {
    subHeader: {
      style: {
        minHeight: '50px', // Set the row height to 50px
      },   rows: {
        style: {
          border: '1px solid #ccc', // Add a border to each row
          borderRadius: '10px', // Add border-radius for a box-like appearance
          marginBottom: '10px', // Add some spacing between rows
        },
      },
      cells: {
        style: {
          padding: "100px!important", // Add padding to the cells
        },
      },
  
  
    },
  };

  return (
    <div>
      <h1 style={{ fontSize: '24px' }}>List of Network Hospitals</h1>
      <br />
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="  Search Hospital"
        style={inputStyle}
      />{' '}
      &nbsp;
      <DataTable
        columns={columns}
        data={filteredHospitals}
        pagination
        paginationPerPage={10}
        noHeader
        dense
        style={tableStyle}
      />
    </div>
  );
};

export default HospitalList;
