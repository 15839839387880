import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
// import data from '../data.json'
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
// import axios from 'axios';
import { Link } from "react-router-dom";
import "../App.css";
import axiosIns from "../components/axiosIns";
// import { Alert } from "react-bootstrap";
import { Alert } from "../components/Toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt,faEdit } from "@fortawesome/free-solid-svg-icons";

const styles = [
  `
  .bt_view{ height: 20px !important; margin-left: 20px; width: 20px !important; font-size: 14px; height: 30px; }
  .rdt_TableRow{ padding: 1%; }
`,
];
const addDropDown = (param) => {
  console.log(param)
  return (
    <>
      <div>
      <Link to={`/service_update/${param._id}`}>
          <FontAwesomeIcon icon={faEdit} className="bt_view" style={{color:'blue'}}/>
        </Link>
        <Link>
          <FontAwesomeIcon icon={faTrashAlt} onClick={handleDeleteAll.bind(this, param._id)} style={{color:'red'}} className="bt_view"/>
        </Link>
      </div>
    </>
  );
};
const handleDeleteAll = async (id) => {
  const choice = window.confirm(
    "Are you sure you want to delete this Mode of Consultant?"
  )
  if (choice) {
    await axiosIns.post('/service_delete', {id: id, status : 2 }).then((res)=>{
      if(res.data.status) { Alert("succ", res.data.msg); window.location.reload(true); }
      else Alert("err", res.data.msg);
    }).catch((err)=>{
      Alert("err", "Please Try Again");
    })
  }
}
const getProfile = (param) => {
  return (
    <>
      <img
        // height={50}
        // width={50}
        style={{
          borderRadius: "10%",
          width: "85px",
          height: "100px",
        }}
        src={param.picture ? param.picture : "./images/noimg.jpg"}
      />
    </>
  );
};

const columns = [
  {
    name: "Mode of Consultant",
    selector: "name",
    sortable: true,
  },
  {
    name: "Picture",
    selector: (param) => getProfile(param),
    sortable: true,
  },
  // {
  //   name : 'Service',
  //   selector: 'service',
  //   sortable:'true'
  // },
  // {
  //   name : 'Date',
  //   selector : 'date',
  //   sortable : true
  // },
  {
    name: "Actions",
    // selector :'actions',
    selector: (param) => addDropDown(param),
  },
];

function ServiceList() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    await axiosIns
      .post("/service_list")
      .then((res) => {
        setData(res.data.data);
        setFilteredData(res.data.data);
        setLoading(false);
        console.log(data);
      })
      .catch((err) => {
        if (err.response.data.msg == "token") {
          localStorage.clear("isLogin");
          window.location.reload("/");
        }
        console.log(err.response);
      });
  };

  useEffect(() => {
    const checkTokenExpiration = setInterval(() => {
      const expirationTime = localStorage.getItem("tokenExpirationTime");
      if (expirationTime && Date.now() > parseInt(expirationTime)) {
        localStorage.clear("isLogin");
        window.location.reload("/");
      }
    }, 1000);

      clearInterval(checkTokenExpiration);
      getData();
  }, []);

  const [filteredData, setFilteredData] = useState(data);

  const filterData = (value) => {
    const lowerCaseValue = value.toLowerCase().trim();
    const filteredData = data.filter((item) =>
      item.name.toLowerCase().includes(lowerCaseValue)
    );
    setFilteredData(filteredData);
  };

  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    filterData(event.target.value);
  };



  return (
    <>
      <style>{styles}</style>
      <div className="App" style={{ width: "100%" }}>
        <div align="left">
        <input style={{width :"30%",borderRadius:"50px",marginRight:"670px",padding:"20px"}}
            type="text"
            placeholder="Search By Mode of Consultant"
            value={searchValue}
            onChange={handleSearch}/>
          <Link to="/add_service" style={{ marginRight: "4px" }}>
            <Button variant="primary">Add</Button>
          </Link>
        </div>
        {loading ? (
          <table className="loader">
            <tbody>
              <tr>
                <td></td>
              </tr>
            </tbody>
          </table>
        ) : (
          <DataTable
            title="Mode of Consultant"
            columns={columns}
            data={filteredData}
            pagination
            highlightOnHover
          />
        )}
      </div>
    </>
  );
}

export default ServiceList;
