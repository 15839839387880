import React, { useState,useEffect } from 'react';
import DataTable from 'react-data-table-component';
import 'bootstrap/dist/css/bootstrap.min.css';
import axiosIns from './components/axiosIns';
import { faClose, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "../src/App.css";

const styles = [`
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  width: 1000px;
  height: 500px;
  border-radius: 15px; 
  border : 1px solid black;
  justify-content: space-between;
}

.close-icon {
  font-size:24px;
  margin-left : 930px;

}

p {
  font-size : 19px;
}
h2 {
  text-align:center;
  line-height:10px;
  margin-top:-15px;
}
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

th, td {
  padding: 18px;
  border-bottom: 1px solid #ddd;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

td:first-child {
  font-weight: bold;
  width : 120px;
}

td:last-child {
  text-align: right;
}


`]

const usePopup = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openPopup = () => {
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  return { isOpen, openPopup, closePopup };
};

const ActionCell = ({ param }) => {
  const { isOpen, openPopup, closePopup } = usePopup();

  return (
    <>
      <div>
        <FontAwesomeIcon
          icon={faEye}
          className="bt_view"
          style={{ color: 'blue', cursor: 'pointer' }}
          onClick={openPopup}
        />
      </div>

      {isOpen && (
        <div className="popup">
          <div className="popup-content">
          <FontAwesomeIcon
          icon={faClose}
          className="close-icon"
          style={{ color: 'blue', cursor: 'pointer' }}
          onClick={closePopup}
        />
            <div>
            <h2>{param.emp_ename}'s Report</h2>
            <br />
            <table>
              <tbody>
                <tr>
                   <td>Patient Name</td>
                   <td>{param.patient_name}</td>
                </tr>
                <tr>
                  <td>Company</td>
                  <td>{param.company}</td>
                </tr>
                <tr>
                  <td>Doctor</td>
                  <td>{param.doctor}</td>
                </tr>
                <tr>
                  <td>Service</td>
                  <td>{param.service}</td>
                </tr>
                <tr>
                  <td>Description</td>
                  <td>{param.description}</td>
                </tr>
                <tr>
                  <td>Feedback</td>
                  <td>{param.feedback}</td>
                </tr>
                <tr>
                  <td>Date</td>
                  <td>{new Date(param.date).toLocaleDateString()}</td>
                </tr>
              </tbody>
            </table>
          </div>
          </div>
        </div>
      )}
    </>
  );
};

const columns = [
  {
    name: "Name & Company",
    cell: (row) => (
      <div>
        {row.emp_ename} 
        <br /> ({row.company})
      </div>
    ),
    sortable: true,
  },
  {
    name : 'Patient Name',
    selector : 'patient_name',
    sortable : true
  },
  {
    name: 'Doctor',
    selector: 'doctor',
    sortable: 'true',
  },
  {
    name : 'Service',
    selector: 'service',
    sortable:'true'
  },
  {
    name : 'Description',
    selector: "description",
    sortable: true
  },
  {
    name : 'Date',
    selector: (param) => new Date(param.date).toLocaleDateString(),
    sortable : true
  },
  {
    name :'Action',
    cell: (param) => <ActionCell param={param} />,
  },
];

function Report() {
  const [data, setData] = useState([]);
  // const userToken = localStorage.getItem('token');
  const [loading, setLoading] = useState(true);
  
  const getData = async()=>{
    await axiosIns.post('/booking_report_list')
    .then((res) => {
      setData(res.data.data)
      setFilteredData(res.data.data);
      setLoading(false)
      console.log(data)
    }).catch((err)=>{
      if (err.response.data.msg == "token") {
        localStorage.clear("isLogin");
        window.location.reload("/");
      }
      console.log(err.response)
    })
  }

  useEffect(()=>{
    const checkTokenExpiration = setInterval(() => {
      const expirationTime = localStorage.getItem("tokenExpirationTime");
      if (expirationTime && Date.now() > parseInt(expirationTime)) {
        localStorage.clear("isLogin");
        window.location.reload("/");
      }
    }, 1000);

      clearInterval(checkTokenExpiration);
      getData();
  }, [])

  const [filteredData, setFilteredData] = useState(data);

    const filterData = (value) => {
      const lowerCaseValue = value.toLowerCase().trim();
      const filteredData = data.filter((item) =>
        item.emp_ename.toLowerCase().includes(lowerCaseValue)
      );
      setFilteredData(filteredData);
    };

    const [searchValue, setSearchValue] = useState("");

    const handleSearch = (event) => {
      setSearchValue(event.target.value);
      filterData(event.target.value);
    };

  return (
    <>
    <style>{styles}</style>
    <div className="App" style={{width:"100%"}}>
      <div align='left'>
      <input style={{width :"30%",borderRadius:"50px",marginRight:"770px",padding:"20px"}}
            type="text"
            placeholder="Search Reports by Name"
            value={searchValue}
            onChange={handleSearch}/>
      </div>
    {loading ? (
        <table className='loader'><tbody><tr><td></td></tr></tbody></table>
      ) : (
      <DataTable
        title="Reports"
        columns={columns}
        data={filteredData}
        pagination
        highlightOnHover
      />)}
    </div>
    </>
  );
}

export default Report;