import React, { useState, useEffect } from "react";
import axiosIns from "../../components/axiosIns";
import * as xlsx from "xlsx";
import { Alert } from "../../components/Toastify";

function BulkAdd() {
  const [file, setFile] = useState(null);
  const [response, setResponse] = useState(null);
  const [employeeData, setData] = useState({ company_name: "", employees: [] });
  const [companylist, setcompanyData] = useState([]);

  const { company_name, employees } = employeeData;

  const handleFileUpload = (event) => {
    setFile(event.target.files[0]);
  };

  const com_list = companylist.map((option) => {
    return (
      <option key={option._id} value={option._id}>
        {option.company_name}
      </option>
    );
  });

  const handleChange = (e) => {
    setData({ ...employeeData, [e.target.name]: e.target.value });
    console.log(employeeData);
  };

  const getData = async () => {
    await axiosIns
      .post("/company_list")
      .then((res) => {
        setcompanyData(res.data.data);
        console.log(companylist);
      })
      .catch((err) => {
        if (err.response.data.msg == "token") {
          localStorage.clear("isLogin");
          window.location.reload("/");
        }
        console.log(err.response);
      });
  };
  useEffect(()=>{
    const checkTokenExpiration = setInterval(() => {
          const expirationTime = localStorage.getItem("tokenExpirationTime");
          if (expirationTime && Date.now() > parseInt(expirationTime)) {
            localStorage.clear("isLogin");
            window.location.reload("/");
          }
        }, 1000);
    
          clearInterval(checkTokenExpiration);
    getData();
    }, []);

  const handleSubmit = async () => {
    if (!file) {
      Alert("warn","Please select a file to upload.");
      return;
    }

    const reader = new FileReader();
    reader.onload = async () => {
      const data = reader.result;
      const workbook = xlsx.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheetData = xlsx.utils.sheet_to_json(workbook.Sheets[sheetName]);

      try {
        employeeData.employees = sheetData;
        setData(employeeData);
        const response = await axiosIns
          .post("/employee_bulk_upload", employeeData)
          .then((res) => {
            Alert("succ",res.data.msg);
            window.location.reload(true);
          })
          .catch((err) => {
            Alert("error",err.data.msg);
          });
      } catch (error) {
        console.error(error);
        setResponse("Error uploading file.");
      }
    };

    reader.readAsBinaryString(file);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "12rem",
      }}
    >
      <h1 style={{ marginBottom: "2rem", fontSize: "40px !important" }}>
        Employee Details Bulk Upload
      </h1>
      <a target="_blank" href="/sample/Employee_Bulk_Upload.xlsx">
        Sample Data Click and Download
      </a>
      <form>
        <div style={{ paddingBottom: "30px" }}>
          <label>
            Company Name <span className="span">*</span>
          </label>
          <select
            className="form-control"
            value={company_name}
            onChange={handleChange}
            name="company_name"
          >
            <option>--Select--</option>
            {com_list}
          </select>
        </div>
      </form>
      <div style={{ marginBottom: "1rem" }}>
        <input type="file" onChange={handleFileUpload} />
      </div>
      <div>
        <button
          style={{
            padding: "0.5rem 1rem",
            borderRadius: "0.25rem",
            backgroundColor: "blue",
            color: "white",
            cursor: "pointer",
            fontSize: "20px !important",
          }}
          onClick={handleSubmit}
        >
          Upload
        </button>
      </div>
      {response && <div style={{ marginTop: "1rem" }}>{response}</div>}
    </div>
  );
}

export default BulkAdd;
