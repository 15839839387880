import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import "../App.css";
import axiosIns from "../components/axiosIns";
import { Alert } from "../components/Toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faEdit } from "@fortawesome/free-solid-svg-icons";
const styles = [
  `
  .bt_view{ height: 20px !important; margin-left: 20px; width: 20px !important; font-size: 14px; height: 30px; }
  .rdt_TableRow{ padding: 1%; }
  #lab_bt{ width: 100px !important;}
`,
];
const addDropDown = (param) => {
  console.log(param);
  return (
    <>
      <div>
        <Link to={`/tariff_update/${param._id}`}>
          <FontAwesomeIcon
            icon={faEdit}
            className="bt_view"
            style={{ color: "blue" }}
          />
        </Link>
        <Link>
          <FontAwesomeIcon
            icon={faTrashAlt}
            onClick={handleDeleteAll.bind(this, param._id)}
            style={{ color: "red" }}
            className="bt_view"
          />
        </Link>
      </div>
    </>
  );
};

const handleDeleteAll = async (id) => {
  const choice = window.confirm("Are you sure you want to delete this Ad?");
  if (choice) {
    await axiosIns
      .post("/tariff_delete", { id: id, status: 2 })
      .then((res) => {
        if (res.data.status) {
          Alert("succ", res.data.msg);
          window.location.reload(true);
        } else Alert("err", res.data.msg);
      })
      .catch((err) => {
        Alert("err", "Please Try Again");
      });
  }
};

const columns = [
  {
    name: "Category",
    selector: "category",
    sortable: true,
  },
  {
    name: "Investigation",
    selector: "investigation",
    sortable: true,
  },
  {
    name: "Actual Rate",
    selector: "rate",
    sortable: true,
  },
  {
    name: "Final Rate",
    selector: "final_rate",
    sortable: true,
  },
  {
    name: "Actions",
    selector: (param) => addDropDown(param),
  },
];

function TariffList1() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    const requestData = { type: "1" };
    await axiosIns
      .post("/tariff_list", requestData)
      .then((res) => {
        // Sort the data in descending order based on _id
        const sortedData = res.data.data.sort((a, b) => b._id.localeCompare(a._id));
        setData(sortedData);
        setFilteredData(sortedData);
        setLoading(false);
        console.log(data);
      })
  
      .catch((err) => {
        if (err.response.data.msg == "token") {
          localStorage.clear("isLogin");
          window.location.reload("/");
        }
        console.log(err.response);
      });
  };

  useEffect(() => {
    const checkTokenExpiration = setInterval(() => {
      const expirationTime = localStorage.getItem("tokenExpirationTime");
      if (expirationTime && Date.now() > parseInt(expirationTime)) {
        localStorage.clear("isLogin");
        window.location.reload("/");
      }
    }, 1000);

    clearInterval(checkTokenExpiration);
    getData();
  }, []);

  const [filteredData, setFilteredData] = useState(data);

  const filterData = (value) => {
    const lowerCaseValue = value.toLowerCase().trim();
    const filteredData = data.filter((item) =>
      item.investigation.toLowerCase().includes(lowerCaseValue)
    );
    setFilteredData(filteredData);
  };

  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    filterData(event.target.value);
  };

  return (
    <>
      <style>{styles}</style>
      <div className="App" style={{ width: "100%" }}>
        <div align="left">
          <input
            style={{
              width: "24%",
              borderRadius: "50px",
              marginRight: "670px",
              padding: "20px",
            }}
            type="text"
            placeholder="Search By Investigation Name"
            value={searchValue}
            onChange={handleSearch}
          />
          <Link to="/tarifflist" style={{ marginRight: "24px" }}>
            <Button id="lab_bt" variant="primary">
              CT and Ultra
            </Button>
          </Link>
          <Link to="/tarifftab" style={{ marginRight: "4px" }}>
            <Button variant="primary">Add</Button>
          </Link>
        </div>
        {loading ? (
          <table className="loader">
            <tbody>
              <tr>
                <td></td>
              </tr>
            </tbody>
          </table>
        ) : (
          <DataTable
            title="Lab and X-Ray"
            columns={columns}
            data={filteredData}
            pagination
            highlightOnHover
          />
        )}
      </div>
    </>
  );
}

export default TariffList1;
