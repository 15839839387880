import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import data from '../data.json'
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
// import axios from 'axios';
import { Link, useParams } from "react-router-dom";
import "../App.css";
import axiosIns from "../components/axiosIns";
import { Alert } from "../components/Toastify";
import {
  faCalendarAlt,
  faEdit,
  faEye,
  faTrash,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
const styles = [
  `
  .bt_view{ height: 20px !important; margin-left: 5px; width: 20px !important; font-size: 14px; height: 30px; }
  .rdt_TableRow{ padding: 1%; }
`,
];
const addDropDown = (param) => {
  return (
    <>
      <div>
        <Link to={`/employee_profile/${param._id}`}>
          <FontAwesomeIcon
            icon={faEye}
            className="bt_view"
            style={{ color: "#50C878" }}
          />
        </Link>
        <Link to={`/employee_update/${param._id}`}>
          <FontAwesomeIcon
            icon={faEdit}
            className="bt_view"
            style={{ color: "blue" }}
          />
        </Link>
        <Link to={`/book_appointment/${param._id}`}>
          <FontAwesomeIcon
            icon={faCalendarAlt}
            className="bt_view"
            style={{ color: "#800080" }}
          />
        </Link>
        <Link>
          <FontAwesomeIcon
            icon={faTrashAlt}
            onClick={handleDeleteAll.bind(this, param._id)}
            className="bt_view"
            style={{ color: "red" }}
          />
        </Link>
      </div>
    </>
  );
};

const handleDeleteAll = async (id) => {
  const choice = window.confirm("Are you sure you want to delete everything?");
  if (choice) {
    await axiosIns
      .post("/employee_delete", { id: id, status: 2 })
      .then((res) => {
        if (res.data.status) {
          Alert("succ", res.data.msg);
          window.location.reload(true);
        } else Alert("err", res.data.msg);
      })
      .catch((err) => {
        Alert("err", "Please Try Again");
      });
  }
};

const getProfile = (param) => {
  return (
    <>
      <img
        // height={50}
        // width={50}
        style={{
          borderRadius: "10%",
          width: "85px",
          height: "100px",
        }}
        src={param.picture ? param.picture : "./images/noimg.jpg"}
      />
    </>
  );
};

const columns = [
  {
    name: "Name & Company",
    cell: (row) => (
      <div>
        {row.name} <br />
        <br /> ({row.company_name})
      </div>
    ),
    sortable: true,
  },
  {
    name: "Picture",
    selector: (param) => getProfile(param),
    sortable: true,
  },
  {
    name: "Mobile",
    selector: (row) => row.mobile,
    sortable: "true",
  },
  {
    name: "Age",
    selector: (row) => row.age,
    sortable: "true",
  },
  {
    name: "Emp ID",
    selector: (row) => row.employee_id,
    sortable: "true",
  },
  {
    name: "Actions",
    // selector :'actions',
    selector: (param) => addDropDown(param),
  },
];

function Employee() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [companylist, setcompanyData] = useState([]);
  const [employeeData, setDatas] = useState({
    company_name: "",
  });
  const { company_name } = employeeData;

  const getData = async () => {
    await axiosIns
      .post("/employee_list")
      .then((res) => {
        setData(res.data.data);
        setFilteredData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response.data.msg == "token") {
          localStorage.clear("isLogin");
          window.location.reload("/");
        }
      });
  };

  const params = useParams();
  const getempdetails = async () => {
    if (params.id) {
      await axiosIns
        .post("/get_employee", { id: params.id })
        .then((res) => {
          setData(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // else window.location.replace("/");
  };

  useEffect(() => {
    const checkTokenExpiration = setInterval(() => {
      const expirationTime = localStorage.getItem("tokenExpirationTime");
      if (expirationTime && Date.now() > parseInt(expirationTime)) {
        localStorage.clear("isLogin");
        window.location.reload("/");
      }
    }, 1000);

    clearInterval(checkTokenExpiration);
    getData();
    getDatas();
    getempdetails();
  }, []);

  const com_list = companylist.map((option) => {
    return (
      <option key={option._id} value={option._id}>
        {option.company_name}
      </option>
    );
  });

  const getDatas = async () => {
    await axiosIns
      .post("/company_list")
      .then((res) => {
        setcompanyData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (e) => {
    setData({ ...employeeData, [e.target.name]: e.target.value });
  };

  const [filteredData, setFilteredData] = useState(data);

  const filterData = (value) => {
    const lowerCaseValue = value.toLowerCase().trim();
    const filteredData = data.filter((item) =>
      item.name.toLowerCase().includes(lowerCaseValue)
    );
    setFilteredData(filteredData);
  };

  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    filterData(event.target.value);
  };

  return (
    <>
      <style>{styles}</style>
      <div className="App" style={{ width: "100%" }}>
        <div align="left">
          {/* <label style={{width:"20%"}}>Company Name
        <select className="form-control" value={company_name} onChange={handleChange}name="company_name">
          <option>--Select--</option>
             {com_list}
          </select></label> */}
          <input
            style={{
              width: "24%",
              borderRadius: "50px",
              marginRight: "670px",
              padding: "20px",
            }}
            type="text"
            placeholder="Search Employee by Name"
            value={searchValue}
            onChange={handleSearch}
          />
          <Link to="/add_employee" style={{ marginRight: "4px" }}>
            <Button variant="primary">Add</Button>
          </Link>
          <Link to="/bulk_add">
            <Button variant="primary" style={{ backgroundColor: "green" }}>
              Bulk Add
            </Button>
          </Link>
        </div>
        {loading ? (
          <table className="loader">
            <tbody>
              <tr>
                <td></td>
              </tr>
            </tbody>
          </table>
        ) : (
          <DataTable
            title="Employee"
            columns={columns}
            data={filteredData}
            pagination
            highlightOnHover
          />
        )}
      </div>
    </>
  );
}

export default Employee;
