import React, { useState,useEffect } from "react";
import "../offers.css";
import { useParams } from "react-router";
import axiosIns from "../components/axiosIns";
import { Alert } from "../components/Toastify";

const TariffTab = () => {
  const [offerData, setOfferData] = useState({
    investigation: "",
    category: "",
    rate: "",
    final_rate: "",
    type: "",
  });

  const { investigation, category, rate, final_rate, type } = offerData;
  const [showLoader, setShowLoader] = useState(false);
  const [selectedValue, setSelectedValue] = useState(false);
  const [selectedType, setSelectedType] = useState(null);

  // const handleRadioChange = (e) => {
  //   setOfferData({ ...offerData, [e.target.name]: e.target.value });
  //   setSelectedValue(e.target.value);
  // };

  const handleChange = (e) => {
    // const selectedValue = event.target.value;
    // setSelectedType(selectedValue); // Make sure this is correctly updating the state
    // console.log("Selected value:", selectedValue);
    setOfferData({ ...offerData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setShowLoader(true);
    // setShowSuccessMsg(true);

    await axiosIns
      .post("/add_tariff", offerData)
      .then((res) => {
        if (res.data.status === true) {
          Alert('succ',res.data.msg)
          window.location.replace("/");
        } else Alert('error',res.data.msg);
      })
      .catch((err) => {
        Alert('error', "Please Try Again....");
      });
      setShowLoader(false);
  };

  const params = useParams();

  useEffect(()=>{
    const checkTokenExpiration = setInterval(() => {
          const expirationTime = localStorage.getItem("tokenExpirationTime");
          if (expirationTime && Date.now() > parseInt(expirationTime)) {
            localStorage.clear("isLogin");
            window.location.reload("/");
          }
        }, 50000);
    
          clearInterval(checkTokenExpiration);
          if (params.id) {
            get_tariff_details();
          }
    }, []);

    const get_tariff_details = async()=>{
      await axiosIns
        .post("/get_tariff", { id: params.id })
        .then((res) => {
          offerData.id = res.data.data._id;
          offerData.investigation = res.data.data.investigation;
          offerData.category = res.data.data.category;
          offerData.rate = res.data.data.rate;
          offerData.final_rate = res.data.data.final_rate;
          offerData.type = res.data.data.type;
          setOfferData({...offerData}); 
          console.log(offerData)
        }).catch((err) => {
          if (err.response.data.msg == "token") {
            localStorage.clear("isLogin");
            window.location.reload("/");
          } else
            Alert ("error", "Please Try Again...");
            setShowLoader(false);
        });
    }

  return (
    <div className="container">
      <div className="row">
        <h1>Tariff Tab</h1>

        <div className="col-md-6">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="investigation">
                Investigation <span className="span">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="investigation"
                placeholder="Enter Investigation"
                value={investigation}
                name="investigation"
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="Category">
                Category <span className="span">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="title"
                placeholder="Enter Category"
                value={category}
                name="category"
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="rate">
                Actual Rate<span className="span">*</span>
              </label>
              <input
                type="number"
                className="form-control"
                id="rate"
                placeholder="Enter Actual Rate"
                value={rate}
                name="rate"
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="final_rate">
                Rate after 20% discount <span className="span">*</span>
              </label>
              <input
                type="number"
                className="form-control"
                id="final_rate"
                placeholder="Discounted Rate"
                value={final_rate}
                name="final_rate"
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
            <label>
                  Type
                </label>
                <select
                  className="form-control"
                  value={type}
                  onChange={handleChange}
                  placeholder="Type"
                  name="type"
                >
                  <option value="">--Select--</option>
                  <option value="1">Lab & X-Ray</option>
                  <option value="2">CT & Ultra Scans</option>
                </select>
              {/* <label style={{ display: "inline-block" }} htmlFor="ent">
                Lab & Xray
              </label>
              {/* <input type="radio" onChange={handleChange} id="type" checked={selectedValue === type} name="type" value={type} /> */}
              {/* <input type="radio" onChange={handleChange} id="type" checked={type==="1"? true : false } name="type" value={type} />
              
              <label style={{ display: "inline-block" }} htmlFor="ent">
                CT & ULTRA SCANS
              </label> */}
              {/* <input type="radio" onChange={handleChange} id="type" checked={selectedValue === type} name="type" value={type} />
              <input type="radio" onChange={handleChange} id="type" checked={type=== "2"? true : false} name="type" value={type} />*/}
            </div>
            <div className="row">
              <div
                className="col-md-12"
                align="center"
                style={{ padding: "10px" }}
              >
                <button
                  onClick={handleSubmit}
                  type="button"
                  className="upload-button" disabled={showLoader} >
                     {showLoader ? (
                  <div className="spinner-border spinner-border-sm" role="status"
                  aria-hidden="true"></div>
                ) : ( 
                  "Save"
                )
              }
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default TariffTab;
