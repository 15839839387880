import React, { useState, useEffect } from "react";
import "../register.css";
import axiosIns from "../../components/axiosIns";
import { storage } from "../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";
import { Alert } from "../../components/Toastify";
import { useParams } from "react-router";

const AddCompany = () => {
  const params = useParams();
  const [companyData, setData] = useState({
    company_name: "",
    password: "",
    contact_person: "",
    no_of_emp: "",
    address: "",
    mobile: "",
    company_type: "",
    email: "",
    picture: "",
  });
  const [companyprofile, setProfile] = useState(null);
  const [profileCheck, setPicCheck] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const {
    company_name,
    password,
    contact_person,
    no_of_emp,
    address,
    mobile,
    company_type,
    email,
    picture,
  } = companyData;

  const handleChange = (e) => {
    setData({ ...companyData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setShowLoader(true);
    if (companyprofile == null) {
      Alert("warn", "Please Choose File");
      setShowLoader(false);
    } else {
      if (profileCheck == null) {
        const imageRef = ref(storage, `company/${companyprofile.name + v4()}`);
        await uploadBytes(imageRef, companyprofile).then(async (res) => {
          return getDownloadURL(ref(storage, res.metadata.fullPath)).then(
            (url) => {
              companyData.picture = url;
              setData(companyData);
              setPicCheck(companyprofile);
            }
          );
        });
      } else {
        if (profileCheck.name != companyprofile.name) {
          const imageRef = ref(
            storage,
            `company/${companyprofile.name + v4()}`
          );
          await uploadBytes(imageRef, companyprofile).then(async (res) => {
            return getDownloadURL(ref(storage, res.metadata.fullPath)).then(
              (url) => {
                companyData.picture = url;
                setData(companyData);
                setPicCheck(companyprofile);
              }
            );
          });
        }
      }
    }

    if (params.id) var url = "/company_update";
    else var url = "/company_register";
    await axiosIns
      .post(url, companyData)
      .then((res) => {
        if (res.data.status === true) {
          Alert("succ", res.data.msg);
          setShowLoader(false);
          window.location.replace("/");
        } else Alert("warn", res.data.msg);
        setShowLoader(false);
      })
      .catch((err) => {
        if (err.response.data.msg == "token") {
          localStorage.clear("isLogin");
          window.location.reload("/");
        } else Alert("error", "Please Try Again");
        setShowLoader(false);
        console.log(err.response);
      });
  };
  useEffect(() => {
    const checkTokenExpiration = setInterval(() => {
      const expirationTime = localStorage.getItem("tokenExpirationTime");
      if (expirationTime && Date.now() > parseInt(expirationTime)) {
        localStorage.clear("isLogin");
        window.location.reload("/");
      }
    }, 1000);

    clearInterval(checkTokenExpiration);
    if (params.id) {
      get_company_details();
    }
  }, []);

  const get_company_details = async () => {
    await axiosIns
      .post("/get_company", { id: params.id })
      .then((res) => {
        companyData.id = res.data.data._id;
        companyData.company_name = res.data.data.company_name;
        companyData.no_of_emp = res.data.data.no_of_emp;
        companyData.contact_person = res.data.data.contact_person;
        companyData.address = res.data.data.address;
        companyData.mobile = res.data.data.mobile;
        companyData.company_type = res.data.data.company_type;
        companyData.email = res.data.data.email;
        companyData.picture = res.data.data.picture;
        setData({ ...companyData });
        setProfile(res.data.data.picture);
        setPicCheck(res.data.data.picture);
        console.log(companyprofile);
        console.log(profileCheck);
      })
      .catch((err) => {
        if (err.response.data.msg == "token") {
          localStorage.clear("isLogin");
          window.location.reload("/");
        } else Alert("error", "Please Try Again...");
        setShowLoader(false);
      });
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <h1 style={{ alignItems: "center" }}>
                Add the Company Details Below
              </h1>
              <div className="col-md-4">
                <label>
                  Company Name <span className="span">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={company_name}
                  onChange={handleChange}
                  placeholder="Company Name"
                  name="company_name"
                />
              </div>
              <div className="col-md-4">
                <label>
                  Type of Company <span className="span">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={company_type}
                  onChange={handleChange}
                  placeholder="Type of Company"
                  name="company_type"
                />
              </div>
              <div className="col-md-4">
                <label>
                  Mobile Number <span className="span">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={mobile}
                  onChange={handleChange}
                  placeholder="Mobile Number"
                  name="mobile"
                />
              </div>
              <div className="col-md-4">
                <label>
                  Mail Id <span className="span">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={email}
                  onChange={handleChange}
                  placeholder="Email Id"
                  name="email"
                />
              </div>
              <div className="col-md-4">
                <label>
                  Number Of Employees <span className="span">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={no_of_emp}
                  onChange={handleChange}
                  placeholder="No.of Employees"
                  name="no_of_emp"
                />
              </div>
              <div className="col-md-4">
                <label>
                  Contact Person <span className="span">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={contact_person}
                  onChange={handleChange}
                  placeholder="Contact person"
                  name="contact_person"
                />
              </div>
              <div className="col-md-4">
                <label>
                  Company Address <span className="span">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={address}
                  onChange={handleChange}
                  placeholder="Company Address"
                  name="address"
                />
              </div>
              {params.id ? null : (
                <div className="col-md-4">
                  <label>
                    Enter Password <span className="span">*</span>
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    value={password}
                    onChange={handleChange}
                    placeholder="Enter Your Password"
                    name="password"
                  />
                </div>
              )}
              <div className="col-md-4">
                <label>
                  Profile <sapn>(155*200)</sapn>
                  <span className="span">*</span>
                </label>
                <input
                  type="file"
                  className="form-control"
                  onChange={(e) => {
                    setProfile(e.target.files[0]);
                  }}
                  placeholder="Age"
                  name="picture"
                />
                <img
                  src={picture ? picture : "/images/noimg.jpg"}
                  width={155}
                  height={200}
                />
              </div>
              <div
                className="col-md-12"
                align="center"
                style={{ padding: "10px" }}
              >
                <button
                  onClick={handleSubmit}
                  type="button"
                  className="btn btn-success"
                  disabled={showLoader}
                >
                  {showLoader ? (
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></div>
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* <form classNameName="registration-form" onSubmit={handleSubmit}>
      <label classNameName="form-label">
        Company Name:
        <input classNameName="form-input" type="text" value={companyName} onChange={(event) => setCompanyName(event.target.value)} />
      </label>
      <label classNameName="form-label">
        First Name:
        <input classNameName="form-input" type="text" value={firstName} onChange={(event) => setFirstName(event.target.value)} />
      </label>
      <label classNameName="form-label">
        Last Name:
        <input classNameName="form-input" type="text" value={lastName} onChange={(event) => setLastName(event.target.value)} />
      </label>
      <label classNameName="form-label">
        Email:
        <input classNameName="form-input" type="email" value={email} onChange={(event) => setEmail(event.target.value)} />
      </label>
      <label classNameName="form-label">
        Phone:
        <input classNameName="form-input" type="tel" value={phone} onChange={(event) => setPhone(event.target.value)} />
      </label>
      <label classNameName="form-label">
        Password:
        <div classNameName="password-input-wrapper">
          <input classNameName="form-input password-input" type={showPassword ? 'text' : 'password'} value={password} onChange={(event) => setPassword(event.target.value)} />
          <button classNameName="password-toggle-button" type="button" onClick={handlePasswordToggle}>
            {showPassword ? 'Hide' : 'Show'}
          </button>
        </div>
      </label>
      <label classNameName="form-label">
        Confirm Password:
        <input classNameName="form-input" type="password" value={confirmPassword} onChange={(event) => setConfirmPassword(event.target.value)} />
      </label>
      <button classNameName="form-button" type="submit">Register</button>
    </form> */}
    </>
  );
};

export default AddCompany;
